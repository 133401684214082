import React  from "react";
const Footer = () => {
    return (<div className=" space-pt--25 space-pb--25">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-12">
                    {/* copyright */}
                    <div className="copyright text-center">
                        &copy; {new Date().getFullYear() + " "} all rights reserved | <a target="_new" href="https://marketplace.zoom.us/docs/sdk/native-sdks/web">Developer Help</a>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default Footer;