import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import ZoomMeetingUI from "../components/zoom-meeting-ui";
import Header from "../components/header";
import Footer from "../components/footer";
const IndexPage = () => {
  return (
    <Fragment>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;600&family=Roboto:wght@400;500&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div
        className="page-wrapper h-100 bg-img d-flex flex-column justify-content-between"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL +
            "/assets/img/backgrounds/bg.jpg"})`
        }}
      >
        <Header />
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-sm-12">
                <h2 className="content__title">
                  Join Meeting
                </h2>
                <div className="subscription-wrapper space-mt--10">
                  <ZoomMeetingUI />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
};
export default IndexPage;
