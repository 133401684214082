import React, { Component, Fragment } from 'react';
import { JoinMeeting } from "../helpers/zoom.helper";
import Toggle from 'react-toggle';
class ZoomMeetingUI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display_name: "",
      meeting_number: "",
      meeting_pwd: "",
      meeting_host: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleMeetingIdChange = this.handleMeetingIdChange.bind(this);
    this.handleMeetingPwdChange = this.handleMeetingPwdChange.bind(this);
    this.handleMeetingRoleChange = this.handleMeetingRoleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({ display_name: event.target.value });
  }
  handleMeetingIdChange(event){
    this.setState({ meeting_number: event.target.value });
  }
  handleMeetingPwdChange(event){
    this.setState({ meeting_pwd: event.target.value });
  }
  handleMeetingRoleChange(){
    this.setState(prevState => ({
      meeting_host: !prevState.meeting_host
    }));
  }
  handleSubmit(e) {
    e.preventDefault();
    JoinMeeting(this.state);
  }
  render() {
    return (
      <Fragment>
        <div className="meeting-form space-mb--10">
          <form onSubmit={this.handleSubmit}>
            <div className="space-mb--10"><input
              type="text"
              placeholder="Name"
              value={this.state.display_name}
              onChange={this.handleChange}
              name="display_name"
              id="display_name"
              required
            />
            </div>
            <div className="space-mb--10">
              <input
                type="text"
                name="meeting_number"
                value={this.state.meeting_number}
                onChange={this.handleMeetingIdChange}
                id="meeting_number"
                placeholder="Meeting Number"
                required
              />
            </div>
            <div className="space-mb--10"><input
              type="text"
              name="meeting_pwd"
              value={this.state.meeting_pwd}
              onChange={this.handleMeetingPwdChange}
              id="meeting_pwd"
              placeholder="Meeting Password"
              required
            />
            </div>
            <div className="space-mb--20">
              <label>
                <Toggle
                  id="meeting_host"
                  defaultChecked={this.state.meeting_host}
                  onChange={this.handleMeetingRoleChange}
                />
                <span className="spanlabel">Host Meeting</span>
              </label>
            </div>
            <div className="space-mb--10">
              <button type="submit" id="join_meeting">Join</button>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}
export default ZoomMeetingUI;
