import $ from "jquery";
import { ZoomMtg } from '@zoomus/websdk';
ZoomMtg.setZoomJSLib('https://source.zoom.us/1.7.5/lib', '/av');
ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();
console.log('checkSystemRequirements');
console.log(JSON.stringify(ZoomMtg.checkSystemRequirements()));
const API_SECRET = 'i5nIsvWDTV2htnAJxsVobWI5FXp0AJlIPgSd';
const API_KEY = 'W5ty_jh4QQW21zVCa4_j0g';

export function JoinMeeting(option) {
  $('#zmmtg-root').show();

  const meetConfig = {
    apiKey: API_KEY,
    apiSecret: API_SECRET,
    meetingNumber: option.meeting_number,
    userName: option.display_name,
    passWord: option.meeting_pwd,
    leaveUrl: 'https://meeting.vikramrajput.com/',
    role: option.meeting_host ? 1 : 0
  };

  ZoomMtg.generateSignature({
    meetingNumber: meetConfig.meetingNumber,
    apiKey: meetConfig.apiKey,
    apiSecret: meetConfig.apiSecret,
    role: meetConfig.role,
    success(res) {
      console.log('signature', res.result);
      ZoomMtg.init({
        leaveUrl: meetConfig.leaveUrl,
        success() {
          ZoomMtg.join(
            {
              meetingNumber: meetConfig.meetingNumber,
              userName: meetConfig.userName,
              signature: res.result,
              apiKey: meetConfig.apiKey,
              passWord: meetConfig.passWord,
              success() {

                console.log('join meeting success');
              },
              error(res) {
                $('#zmmtg-root').hide();
                console.log(res);
              }
            }
          );
        },
        error(res) {
          console.log(res);
        }
      });
    }
  });
}