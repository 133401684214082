import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./assets/scss/style.scss";
import CreateMeeting from "./pages/CreateMeeting";
import IndexPage from "./pages/Index";
import NotFound from "./pages/NotFound";
function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path={process.env.PUBLIC_URL + "/"}
          component={IndexPage}
        />
        <Route
          path={process.env.PUBLIC_URL + "/create-meeting"}
          component={CreateMeeting}
        />
        <Route exact component={NotFound} />
      </Switch>
    </Router>
  );
}
export default App;
